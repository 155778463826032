<template>
  <div>
    <div
      class="row mt-5 w-85 big-div"
      style="position: relative; z-index: 100"
      :style="
        getPageDesign() +
        `zoom:${this.pageZoomValue ? this.pageZoomValue : 90}%;`
      "
    >
      <div
        class="bg"
        v-if="getImageAlign == 'no'"
        :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
        :style="
          getbackgroundImage(
            IsImageAdded,
            pageDesign.imageBlock,
            getImageAlign,
            isDesktopView
          ) + getBgDivStyles()
        "
      ></div>
      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showLeftImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          @mouseover="addHoverOnImageBlock"
          @mouseleave="removeHoverOnImageBlock"
          :class="isDesktopView ? 'image-division' : null"
          :style="getImageOpacity(pageDesign.imageBlock.style)"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) + getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>

      <div
        :class="!IsImageAdded ? 'col-12' : imageAddedLayout"
        class=" "
        :style="
          getMainPaddingCss(pageDesign.pageStyles, isDesktopView) +
          getScrollCSS(
            pageDesign.pageStyles,
            isDesktopView,
            pageDesign.imageBlock.style
          ) +
          calculateContentWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive,
            pageDesign.pageStyles
          )
        "
      >
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            height: 100%;
          "
        >
          <draggable
            v-model="pageDesignBlocks"
group="actions"
v-bind="dragOptions"
chosen-class="chosen"
drag-class="drag"
            @start="dragging = true"
            @end="dragging = false"
            style="cursor: move; overflow-y: scroll"
            class="dragArea list-group h-100"
          >
            <div
              v-for="(pro, ind) in pageDesignBlocks"
              :key="`${pro.type}-${ind}`"
              draggable="false"
              :class="(dragging && pro.type != 'button') ? 'dragElementBorder' : null"
              @drop="handleElementDrop($event,ind)"
                 
                 @dragover.prevent
                @dragenter.prevent
              @click="changeCustomize(ind)"
              @mouseover="addHoverPorperty(ind)"
              @mouseleave="removeHoverPorperty(ind)"
            >
              <div
              :id="`${pro.type}-${ind}`"
                :style="getHeightWidth(pro.style) + getDivBorderCss(pro)"
                class="content"
                style="position: relative"
              >
              <EditorAction v-if="pro.customizeStatus" :deleteCondition="getDeleteButtonCondition(pro)" :currElementIndex="ind" :currElementType="pro.type"/>
                <div
                  v-if="pro.type == 'text'"
                  :style="
                    getColorCss(pro.style) +
                    getPaddingCss(pro.style) +
                    getMarginCss(pro.style, isDesktopView) +
                    getColorWithOpacity(pro.style) +
                    getTextRotate(pro.style) +
                    getLineHeight(pro.style) +
                    getTextAlign(pro.style) +
                    getFontStyle(pro.style,isDesktopView)
                  "
                  v-html="getEditorPersonalisedValueStyle(pro.content)"
                ></div>
                <!-- </VueDragResize> -->

                <div
                  v-if="pro.type == 'paymentButton'"
                  :style="`display:flex;justify-content: ${pro.style.position};`"
                >
                  <button
                    v-if="currentPaymentMethod == 'Stripe'"
                    :style="getButtonCss(pro.style, isDesktopView)"
                  >
                    <span
                      :style="{
                        opacity: `${pro.style.textOpacity}%`,
                        fontSize: `${pro.style.fontSize}px`,
                      }"
                    >
                      {{ pro.text }}
                    </span>
                    {{ pro.style.alignItems }}
                  </button>
                  <div
                    id="PaypalButton"
                    v-if="currentPaymentMethod == 'Paypal'"
                    style="width: 28%"
                  ></div>
                </div>
                <div
                  v-if="pro.type == 'price'"
                  :style="getMarginCss(pro.style, isDesktopView)"
                >
                  <div>
                    <p class="m-0" :style="getPriceCss(pro.style)">
                      <span>{{ pro.selectedCurrency.currencySymbol }}</span
                      >{{ pro.amountValue }}
                    </p>
                  </div>
                </div>
                <div
v-if="pro.type == 'imageComp'"

>
<InlineImageComp :pro="pro" :isDesktopView="isDesktopView" />
</div>

                <div
                  v-if="pro.type == 'tooltip'"
                  :style="
                    'display:flex;justify-content:' + pro.style.position + ';'
                  "
                >
                  <button
                    :style="
                      getButtonCss(pro.style, isDesktopView) +
                      getCircularBtn(pro.isBtnRounded)
                    "
                    @mouseenter="pro.isTooltipVisible = true"
                    @mouseleave="pro.isTooltipVisible = false"
                  >
                    <span v-if="pro.useIcon">
                      <BIcon :icon="pro.selectedIcon" />
                    </span>

                    <span
                      v-else
                      :style="{
                        opacity: `${pro.style.textOpacity}%`,
                        fontSize: `${pro.style.fontSize}px`,
                      }"
                    >
                      {{ pro.text }}
                    </span>
                  </button>
                  <ToolTipComp
                    v-if="pro.isTooltipVisible"
                    :tooltipData="pro"
                    :isDesktopView="isDesktopView"
                  />
                </div>
                <div
                    v-if="pro.type == 'html'"
                    :style="
                      getMarginCss(pro.style, isDesktopView) +
                      getColorWithOpacity(pro.style) +
                      getTextRotate(pro.style) +
                      getTextAlign(pro.style)
                    "
                  >
                    <HtmlElement :pro="pro" :isDesktopView="isDesktopView" />
                  </div>
              </div>
            </div>
          </draggable>
          <!-- Static Button -->
          <div>
            <div
              @click="changeStaticBtnCustomisation(getButtonIndex)"
              @mouseover="addStaticBtnHoverPorperty()"
              @mouseleave="removeStaticBtnHoverPorperty()"
            >
              <div
                :style="
                  getHeightWidth(StaticButtonElement.style) +
                  getStaticButtonDivCss(staticButtonStyles)
                "
              >
                <div class="w-100">
                  <div
                    class="d-flex"
                    :style="` justify-content: ${StaticButtonElement.style.buttonPosition}`"
                  >
                    <button
                      :style="
                        getCommonButtonCSS(
                          StaticButtonElement.style,
                          isDesktopView
                        ) +
                        getBackNextButtonCss(
                          StaticButtonElement.content.backButton.style,isDesktopView
                        )
                      "
                    >
                      <span
                        :style="{
                          opacity: `${StaticButtonElement.content.backButton.style.textOpacity}%`,
                          fontSize: `${StaticButtonElement.content.backButton.style.fontSize}px`,
                        }"
                        >{{ StaticButtonElement.content.backButton.text }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Static Button End -->
        </div>
      </div>

      <div
        class="col-6 col-xs-12 col-md-6 col-sm-12 px-0 col-12 col-xs-12 px-0"
        v-show="showRightImage"
        :class="{
          'col-12 col-lg-12 col-xs-12': !isDesktopView,
          'image-division': isDesktopView,
        }"
        :style="
          calculateImageWidth(
            pageDesign.imageBlock,
            isDesktopView,
            IsImageVariableWidthActive
          )
        "
      >
        <div
          @click="activeImageCustomize"
          @mouseover="addHoverOnImageBlock"
          @mouseleave="removeHoverOnImageBlock"
          :class="isDesktopView ? 'image-division' : ''"
        >
          <div :style="height100(isDesktopView)">
            <LazyImage
              :key="getImageSrc(pageDesign.imageBlock, isDesktopView)"
              :style="
                getUniversalImageStyle(
                  isDesktopView,
                  pageDesign.imageBlock.style,
                  IsImageVariableWidthActive,
                  pageDesign.imageBlock
                ) +
                getImageOpacity(pageDesign.imageBlock.style) +
                getImageRotation(pageDesign.imageBlock.style)
              "
              :class="[
                `pic-${getSelectedPage.order}`,
                getImageSrc(pageDesign.imageBlock, isDesktopView) ==
                '/images/default_start_page.png'
                  ? 'default-bg'
                  : '',
              ]"
              :src="
                MainImgSrc(getImageSrc(pageDesign.imageBlock, isDesktopView))
              "
              alt
            />
          </div>
        </div>
        <button id="clear" class="hide close-icon" @click="deleteImage">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
              fill="black"
              fill-opacity="0.5"
            />
            <path
              d="M10.2857 23.2592C10.2857 24.4 11.1428 25.3333 12.1905 25.3333H19.8095C20.8571 25.3333 21.7143 24.4 21.7143 23.2592V10.8148H10.2857V23.2592ZM12.1905 12.8888H19.8095V23.2592H12.1905V12.8888ZM19.3333 7.70366L18.3809 6.66663H13.619L12.6666 7.70366H9.33331V9.77774H22.6666V7.70366H19.3333Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import centralEventBus from "./centralEventBus";
import draggable from "vuedraggable";
import { mapGetters, mapMutations } from "vuex";
import cssFunctionMixin from "../../mixin/cssFunction";
import ToolTipComp from "../../../quizPreviewComp/ToolTipComp.vue";

import EditorAction from "../../../../components/EditorActions.vue"
export default {
  mixins: [cssFunctionMixin],
  props: ["isDesktopView"],
  components: {
    draggable,
    ToolTipComp,
    EditorAction,
    HtmlElement: () =>
      import(
        /* webpackChunkName: "HtmlElement" */ "../../../quizPreviewComp/HtmlElement.vue"
      ),
      InlineImageComp: () =>
import(
/* webpackChunkName: "InlineImageComp" */ "../../../quizPreviewComp/components/InlineImageComp.vue"
),
  },
  data() {
    return {
      editMode: false,
      dragging: false,
      activeSideBar: "pageStyle",
      //   pageDesign: {},
      staticButtonStyles: {
        hoverStatus: false,
        customizeStatus: false,
      },
      isPaypalBtnLoading: false,
      paypal_sdk_url: `https://www.paypal.com/sdk/js`,
      paypalIntent: `capture`,
    };
  },
  computed: {
    dragOptions(){
return {
animation: 200,
group: "description",
disabled: false,
ghostClass: "ghost",
chosenClass: "custom-chosen",
};
},
    pageDesign() {
      return this.getSelectedPage.pageDesign;
    },

    pageDesignBlocks: {
      get() {
        return this.pageDesign.blocksArray;
      },
      set(newValue) {
        centralEventBus.$emit("handleBlocksArrayDraggable", newValue);
      },
    },

    CurrentPage() {
      return this.getSelectedPage;
    },
    getButtonIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return index;
    },
    StaticButtonElement() {
      const index = this.pageDesign.blocksArray.findIndex(
        (x) => x.type == "button"
      );
      return this.pageDesign.blocksArray[index];
    },
    getImageAlign(){
      if (this.pageDesign.pageStyles.useMobileImageAlign && !this.isDesktopView) {
     return  this.pageDesign.pageStyles.mobileImageAlign ;
    } else {
      return this.pageDesign.pageStyles.imageAlign;
    }
    },
    showLeftImage() {
      return (
        (this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayDesktop &&
          this.getImageAlign == "left") ||
        (!this.isDesktopView &&
          this.pageDesign.imageBlock.style.displayMobile &&
          (this.getImageAlign == "left" ||
            this.getImageAlign == "right"))
      );
    },
    showRightImage() {
      return (
        this.isDesktopView &&
        this.pageDesign.imageBlock.style.displayDesktop &&
        this.getImageAlign == "right"
      );
    },

    getPriceCompIndex() {
      return this.pageDesign.blocksArray.findIndex((e) => e.type == "price");
    },
    getPricePaymentButtonIndex() {
      return this.pageDesign.blocksArray.findIndex(
        (e) => e.type == "paymentButton"
      );
    },
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getEditorPageView",
      "getPageZoom",
    ]),
    pageZoomValue() {
      return this.getPageZoom;
    },
    CurrentSelectedPage() {
      return this.getSelectedPage;
    },

    IsImageAdded() {
      if (
        this.getImageSrc(this.pageDesign.imageBlock, this.isDesktopView) !== ""
      )
        return true;
      return false;
    },
    IsImageVariableWidthActive() {
      return this.IsImageAdded && this.getImageAlign != "no"
        ? true
        : false;
    },
    imageAddedLayout() {
      if (this.getImageAlign == "no") return "col-12";
      if (!this.isDesktopView) return "col-12";
      return "col-12 col-xs-12 col-md-6 col-sm-12  col-12 col-xs-12";
    },
    currentPaymentMethod() {
      return this.pageDesign.blocksArray[this.getPriceCompIndex]
        .selectedPaymentMethod;
    },
    paymentBlockData() {
      return this.pageDesign.blocksArray[this.getPriceCompIndex];
    },
    PaypalButtonStyles() {
      return this.pageDesign.blocksArray[this.getPricePaymentButtonIndex]
        .paypalButtonData;
    },
  },
  watch: {
    currentPaymentMethod: {
      handler(newVal) {
        if (newVal === "Paypal") {
          this.loadPayPalSDK();
        }
      },
    },
    PaypalButtonStyles: {
      deep: true,
      handler() {
        this.loadPayPalSDK();
      },
    },
  },

  methods: {
    handleElementDrop(evt , index){
      const item = evt.dataTransfer.getData('item')
      if(item ){
        let obj = {
          index:index,
          item:item,
          currPage:this.getSelectedPage.value
        }
        centralEventBus.$emit("handleAddElementDrop", obj);
        localStorage.setItem('DragStart',false)
      }
      
    },
    ...mapMutations(["setImageUpload","setCurrSidebarView"]),

    getDivBorderCss(block) {
      if (block.customizeStatus) return "border:2px solid #4065d5";
      else if (block.hoverStatus) return "border:2px solid #ada9a9";
      else return "border:2px solid transparent";
    },

  async  changeCustomize(index) {
      if (this.staticButtonStyles.customizeStatus) {
        this.staticButtonStyles.customizeStatus = false;
      } 
await this.setCurrSidebarView('pageDesign')
      centralEventBus.$emit("changeCustomize", index);
    },
    addHoverPorperty(index) {
      if (
        this.pageDesign.blocksArray[index].type !== "button" &&
        this.pageDesign.blocksArray[index].customizeStatus == false
      )
        centralEventBus.$emit("addHover", index);
    },

    removeHoverPorperty(index) {
      centralEventBus.$emit("removeHover", index);
    },
    addStaticBtnHoverPorperty() {
      if (this.staticButtonStyles.customizeStatus == false)
        this.staticButtonStyles.hoverStatus = true;

      centralEventBus.$emit("addStaticHover", this.staticButtonStyles);
    },

    removeStaticBtnHoverPorperty() {
      this.staticButtonStyles.hoverStatus = false;

      centralEventBus.$emit("removeStaticHover", this.staticButtonStyles);
    },

    changeStaticBtnCustomisation(index) {
      // this.pageDesign.imageBlock.customizeStatus = false;
      this.staticButtonStyles.customizeStatus = true;

      centralEventBus.$emit("changeStaticCustomisation", index);
    },

    getPageDesign() {
      let design =
        "text-align:" +
        this.pageDesign.pageStyles.textAlign +
        ";color:" +
        this.pageDesign.pageStyles.color +
        "; background:" +
        this.pageDesign.pageStyles.backgroundColor +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; color:" +
        this.pageDesign.pageStyles.color +
        "; text-align:" +
        this.pageDesign.pageStyles.textAlign +
        "; height:" +
        this.pageDesign.pageStyles.height +
        "px;" +
        (this.getEditorPageView == "desktop"
          ? "width:" + this.pageDesign.pageStyles.width + "px;"
          : "width:350px;");

      return design;
    },
    deleteBlock(index) {
      centralEventBus.$emit("deleteBlock", index);
    },
    async activeImageCustomize() {
await this.setCurrSidebarView('pageDesign')
centralEventBus.$emit("activeImageCustomize");
},

    addHoverOnImageBlock() {
      if (this.pageDesign.imageBlock.customizeStatus == true)
        this.pageDesign.imageBlock.hoverStatus = true;
    },
    removeHoverOnImageBlock() {
      this.pageDesign.imageBlock.hoverStatus = false;
    },
    deleteImage() {
      centralEventBus.$emit("deleteImage");
    },
    getDeleteButtonCondition(pro) {
      if (pro.customizeStatus) {
        if (pro.type == "text" && this.minTextCount <= 1) return false;
        if (pro.type == "price") return false;
        if (pro.type == "button") return false;
        if (pro.type == "paymentButton") return false;
        return true;
      }
    },

    // Paypal Functionality
    loadPayPalSDK() {
      this.isPaypalBtnLoading = true;
      const paypal_sdk_url = "https://www.paypal.com/sdk/js";
      const currency = "USD";
      const intent = "capture";
      const client_id =
        "ATlqOxbE-Xy4d-zjQPKE94crMTz8hXeZXE7iwBL9LAneWWmifX1WzMUHTDfIL6keD1Mj7lljoWN8ltxF";

      this.url_to_head(
        `${paypal_sdk_url}?client-id=${client_id}&enable-funding=venmo&currency=${currency}&intent=${intent}`
      )
        .then(() => {
          this.isPaypalBtnLoading = false;
          this.initPayPalButton();
        })
        .catch((error) => {
          this.isPaypalBtnLoading = false;
          console.error(error);
        });
    },
    url_to_head(url) {
      return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = url;
        script.onload = resolve;
        script.onerror = () => reject(new Error("Error loading script."));
        document.head.appendChild(script);
      });
    },
    initPayPalButton() {
      if (!window.paypal) {
        console.error("PayPal SDK not loaded.");
        return;
      }
      window.paypal
        .Buttons({
          style: {
            shape: this.PaypalButtonStyles.shape,
            color: this.PaypalButtonStyles.color,
            layout: this.PaypalButtonStyles.layout,
            label: this.PaypalButtonStyles.label,
          },
          createOrder: () => {
            console.log("On Create Data ,Action");
            // Code to create the order
          },
          onApprove: (data, actions) => {
            console.log("On Apprive Data ,Action", data, actions);
          },
          onCancel: (data) => {
            console.log("Data Cancel", data);
          },
          onError: (err) => {
            if (err) {
              throw err;
            }
          },
        })
        .render(`#PaypalButton`);
    },
  },

  mounted() {
    if (this.currentPaymentMethod === "Paypal") {
      this.loadPayPalSDK();
    }
  },
  beforeDestroy() {
    const paypalScript = document.querySelector(
      'script[src^="https://www.paypal.com/sdk/js"]'
    );
    if (paypalScript) {
      paypalScript.remove();
    }
  },
};
</script>

<style>
.image-division {
  height: 100%;
  cursor: pointer;
}
</style>
